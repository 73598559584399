export class Container {
  private _providers: { [key: string]: any } = {};

  get providers() {
    return this._providers;
  }

  set providers(providers: { [key: string]: any }) {
    this._providers = providers;
  }

  resolve(token: string) {
    const matchedProviderKey = Object.keys(this._providers).find((key) => key === token);
    const matchedProvider = this._providers[matchedProviderKey];

    if (matchedProvider) {
      return matchedProvider;
    } else {
      throw new Error(`No provider found for ${token}!`);
    }
  }
}

export const container = new Container();

export function getContainer() {
  return container;
}
