import { Subject, Subscription, concatMap, delay, filter, take, timer } from "rxjs";

const INITIAL_DELAY = 100;
const INTERVAL = 200;

export class VeggaLoader {
  isLoadingSubject = new Subject<boolean>();
  subscriptions: Subscription[] = [];

  constructor() {
    this.setLoaderSubscription();
  }

  get isLoading$() {
    return this.isLoadingSubject.asObservable()
  }

  waitFor(subs: Subscription) {
    // init loading
    this.isLoadingSubject.next(true);
    // add subscription to the list
    this.updateCachedSubs(subs);
    // init loader subscription
    this.setLoaderSubscription();
  }

  private updateCachedSubs(subs: Subscription): void {
    this.subscriptions = this.subscriptions.filter(subs => !subs.closed);
    this.subscriptions.push(subs);
  }

  private setLoaderSubscription(): void {
    timer(INITIAL_DELAY, INTERVAL).pipe(
      // filter emissions when subscriptions array contains opened at least one opened subscription
      filter(() => this.subscriptions.every(subs => subs.closed)),
      // close timer observable to get it ready for next emissions
      take(1),
      // set delay 
      // delay(750)
    )
      .subscribe(() => this.isLoadingSubject.next(false));
  }
}