import { Injectable } from "../../di";
import { VeggaLoader } from "../../http/veggaLoader";
import {Observable} from 'rxjs';

@Injectable('veggaFacade')
export class VeggaFacade {
  loader = new VeggaLoader();

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }
}