export const graphData = {
  chart: {
    spacingRight: 50,
  },
  series: [
    {
      yAxis: 2,
      name: 'A2500 Meteo Progres',
      type: 'areaspline',
      data: [
        [1696197600000, Math.floor(Math.random() * 20) + 24.5],
        [1696284000000, Math.floor(Math.random() * 20) + 23.3],
        [1696370400000, Math.floor(Math.random() * 20) + 22.1],
        [1696456800000, Math.floor(Math.random() * 20) + 23.3],
        [1696543200000, Math.floor(Math.random() * 20) + 22.8],
        [1696629600000, Math.floor(Math.random() * 20) + 22.5],
        [1696716000000, Math.floor(Math.random() * 20) + 22.3],
        [1696802400000, Math.floor(Math.random() * 20) + 22.2],
        [1696888800000, Math.floor(Math.random() * 20) + 21.7],
        [1696975200000, Math.floor(Math.random() * 20) + 21.2],
        [1697061600000, Math.floor(Math.random() * 20) + 20.7],
        [1697148000000, Math.floor(Math.random() * 20) + 20.2],
        [1697234400000, Math.floor(Math.random() * 20) + 20.5],
        [1697320800000, Math.floor(Math.random() * 20) + 20.9],
        [1697407200000, Math.floor(Math.random() * 20) + 19.9],
        [1697493600000, Math.floor(Math.random() * 20) + 18.5],
        [1697580000000, Math.floor(Math.random() * 20) + 19.2],
        [1697666400000, Math.floor(Math.random() * 20) + 21.4],
        [1697752800000, Math.floor(Math.random() * 20) + 18.2],
        [1697839200000, Math.floor(Math.random() * 20) + 16.2],
        [1697925600000, Math.floor(Math.random() * 20) + 14.3],
        [1698012000000, Math.floor(Math.random() * 20) + 14],
        [1698098400000, Math.floor(Math.random() * 20) + 18.7],
        [1698184800000, Math.floor(Math.random() * 20) + 15.6],
        [1698271200000, Math.floor(Math.random() * 20) + 16.2],
        [1698357600000, Math.floor(Math.random() * 20) + 18.2],
        [1698444000000, Math.floor(Math.random() * 20) + 15.8],
        [1698530400000, Math.floor(Math.random() * 20) + 15.4],
        [1698620400000, Math.floor(Math.random() * 20) + 17],
        [1698706800000, Math.floor(Math.random() * 20) + 14.2],
        [1698793200000, Math.floor(Math.random() * 20) + 12.2],
        [1698879600000, Math.floor(Math.random() * 20) + 12],
        [1698966000000, Math.floor(Math.random() * 20) + 13.7],
        [1699052400000, Math.floor(Math.random() * 20) + 10.5],
        [1699138800000, Math.floor(Math.random() * 20) + 11.8],
        [1699225200000, Math.floor(Math.random() * 20) + 13.8],
        [1699311600000, Math.floor(Math.random() * 20) + 11.8],
        [1699398000000, Math.floor(Math.random() * 20) + 9.3],
        [1699484400000, Math.floor(Math.random() * 20) + 9.1],
        [1699570800000, Math.floor(Math.random() * 20) + 10.9],
        [1699657200000, Math.floor(Math.random() * 20) + 13],
        [1699743600000, Math.floor(Math.random() * 20) + 12.8],
        [1699830000000, Math.floor(Math.random() * 20) + 16.8],
        [1699916400000, Math.floor(Math.random() * 20) + 15.8],
        [1700002800000, Math.floor(Math.random() * 20) + 16.3],
        [1700089200000, Math.floor(Math.random() * 20) + 15.7],
        [1700175600000, Math.floor(Math.random() * 20) + 15.4],
        [1700262000000, Math.floor(Math.random() * 20) + 13.6],
        [1700348400000, Math.floor(Math.random() * 20) + 11.4],
        [1700434800000, Math.floor(Math.random() * 20) + 11.1],
        [1700521200000, Math.floor(Math.random() * 20) + 11.6],
        [1700607600000, Math.floor(Math.random() * 20) + 12.5],
        [1700694000000, Math.floor(Math.random() * 20) + 11.4],
        [1700780400000, Math.floor(Math.random() * 20) + 8],
        [1700866800000, Math.floor(Math.random() * 20) + 8.1],
        [1700953200000, Math.floor(Math.random() * 20) + 8.7],
        [1701039600000, Math.floor(Math.random() * 20) + 7.2],
        [1701126000000, Math.floor(Math.random() * 20) + 8.4],
        [1701212400000, Math.floor(Math.random() * 20) + 12.7],
        [1701298800000, Math.floor(Math.random() * 20) + 11.4],
        [1701385200000, Math.floor(Math.random() * 20) + 12.3],
        [1701471600000, Math.floor(Math.random() * 20) + 11.1],
        [1701558000000, Math.floor(Math.random() * 20) + 8.8],
        [1701644400000, Math.floor(Math.random() * 20) + 5.2],
        [1701730800000, Math.floor(Math.random() * 20) + 7.3],
        [1701817200000, Math.floor(Math.random() * 20) + 7.4],
        [1701903600000, Math.floor(Math.random() * 20) + 6.3],
        [1701990000000, Math.floor(Math.random() * 20) + 5.7],
        [1702076400000, Math.floor(Math.random() * 20) + 9.7],
        [1702162800000, Math.floor(Math.random() * 20) + 8.6],
        [1702249200000, Math.floor(Math.random() * 20) + 10.7],
        [1702335600000, Math.floor(Math.random() * 20) + 10.5],
        [1702422000000, Math.floor(Math.random() * 20) + 9.8],
        [1702508400000, Math.floor(Math.random() * 20) + 11.6],
        [1702594800000, Math.floor(Math.random() * 20) + 9.9],
        [1702681200000, Math.floor(Math.random() * 20) + 8.2],
        [1702767600000, Math.floor(Math.random() * 20) + 6],
        [1702854000000, Math.floor(Math.random() * 20) + 3],
        [1702940400000, Math.floor(Math.random() * 20) + 0.4],
        [1703026800000, Math.floor(Math.random() * 20) + 1.2],
        [1703113200000, Math.floor(Math.random() * 20) + 1.3],
        [1703199600000, Math.floor(Math.random() * 20) + 8.4],
        [1703286000000, Math.floor(Math.random() * 20) + 12.7],
        [1703372400000, Math.floor(Math.random() * 20) + 9],
        [1703458800000, Math.floor(Math.random() * 20) + 4.8],
        [1703545200000, Math.floor(Math.random() * 20) + 4.5],
        [1703631600000, Math.floor(Math.random() * 20) + 1.9],
        [1703718000000, Math.floor(Math.random() * 20) + 1],
        [1703804400000, Math.floor(Math.random() * 20) + 0.9],
        [1703890800000, Math.floor(Math.random() * 20) + 1.8],
        [1703977200000, Math.floor(Math.random() * 20) + 4.2],
        [1704063600000, Math.floor(Math.random() * 20) + 6.1],
        [1704150000000, Math.floor(Math.random() * 20) + 6.7],
        [1704236400000, Math.floor(Math.random() * 20) + 3.9],
        [1704322800000, Math.floor(Math.random() * 20) + 8.1],
        [1704409200000, Math.floor(Math.random() * 20) + 9.2],
        [1704495600000, Math.floor(Math.random() * 20) + 8.7],
        [1704582000000, Math.floor(Math.random() * 20) + 6.9],
        [1704668400000, Math.floor(Math.random() * 20) + 6.2],
        [1704754800000, Math.floor(Math.random() * 20) + 4.7],
        [1704841200000, Math.floor(Math.random() * 20) + 3.8],
        [1704927600000, Math.floor(Math.random() * 20) + 5.6],
        [1705014000000, Math.floor(Math.random() * 20) + 4.6],
        [1705100400000, Math.floor(Math.random() * 20) + 1.9],
        [1705186800000, Math.floor(Math.random() * 20) + 0.4],
        [1705273200000, Math.floor(Math.random() * 20) + 2.1],
        [1705359600000, Math.floor(Math.random() * 20) + 7.3],
        [1705446000000, Math.floor(Math.random() * 20) + 9.6],
        [1705532400000, Math.floor(Math.random() * 20) + 11],
        [1705618800000, Math.floor(Math.random() * 20) + 12.3],
        [1705705200000, Math.floor(Math.random() * 20) + 7.2],
        [1705791600000, Math.floor(Math.random() * 20) + 4.1],
        [1705878000000, Math.floor(Math.random() * 20) + 1.8],
        [1705964400000, Math.floor(Math.random() * 20) + 1.4],
        [1706050800000, Math.floor(Math.random() * 20) + 5.3],
        [1706137200000, Math.floor(Math.random() * 20) + 6.3],
        [1706223600000, Math.floor(Math.random() * 20) + 5.8],
        [1706310000000, Math.floor(Math.random() * 20) + 5.7],
        [1706396400000, Math.floor(Math.random() * 20) + 5.7],
        [1706482800000, Math.floor(Math.random() * 20) + 8.6],
        [1706569200000, Math.floor(Math.random() * 20) + 10.5],
        [1706655600000, Math.floor(Math.random() * 20) + 10.4],
        [1706742000000, Math.floor(Math.random() * 20) + 6.6],
        [1706828400000, Math.floor(Math.random() * 20) + 5.6],
        [1706914800000, Math.floor(Math.random() * 20) + 5.9],
        [1707001200000, Math.floor(Math.random() * 20) + 4.2],
        [1707087600000, Math.floor(Math.random() * 20) + 5.1],
        [1707174000000, Math.floor(Math.random() * 20) + 4.6],
        [1707260400000, Math.floor(Math.random() * 20) + 4.7],
        [1707346800000, Math.floor(Math.random() * 20) + 5.8],
        [1707433200000, Math.floor(Math.random() * 20) + 9.1],
        [1707519600000, Math.floor(Math.random() * 20) + 11],
        [1707606000000, Math.floor(Math.random() * 20) + 10.6],
        [1707692400000, Math.floor(Math.random() * 20) + 8.5],
        [1707778800000, Math.floor(Math.random() * 20) + 9.4],
        [1707865200000, Math.floor(Math.random() * 20) + 10.7],
        [1707951600000, Math.floor(Math.random() * 20) + 11.9],
        [1708038000000, Math.floor(Math.random() * 20) + 14.5],
        [1708124400000, Math.floor(Math.random() * 20) + 10.8],
        [1708210800000, Math.floor(Math.random() * 20) + 11.1],
        [1708297200000, Math.floor(Math.random() * 20) + 10.2],
        [1708383600000, Math.floor(Math.random() * 20) + 12],
        [1708470000000, Math.floor(Math.random() * 20) + 10.4],
        [1708556400000, Math.floor(Math.random() * 20) + 10.4],
        [1708642800000, Math.floor(Math.random() * 20) + 12.2],
        [1708729200000, Math.floor(Math.random() * 20) + 8.9],
        [1708815600000, Math.floor(Math.random() * 20) + 7.5],
        [1708902000000, Math.floor(Math.random() * 20) + 8],
        [1708988400000, Math.floor(Math.random() * 20) + 9.4],
        [1709074800000, Math.floor(Math.random() * 20) + 9.3],
        [1709161200000, Math.floor(Math.random() * 20) + 10.4],
        [1709247600000, Math.floor(Math.random() * 20) + 9.6],
        [1709334000000, Math.floor(Math.random() * 20) + 9.6],
        [1709420400000, Math.floor(Math.random() * 20) + 10.4],
        [1709506800000, Math.floor(Math.random() * 20) + 7.7],
        [1709593200000, Math.floor(Math.random() * 20) + 8.1],
        [1709679600000, Math.floor(Math.random() * 20) + 9.7],
        [1709766000000, Math.floor(Math.random() * 20) + 8.6],
        [1709852400000, Math.floor(Math.random() * 20) + 10.5],
        [1709938800000, Math.floor(Math.random() * 20) + 10],
        [1710025200000, Math.floor(Math.random() * 20) + 8.3],
        [1710111600000, Math.floor(Math.random() * 20) + 8.1],
        [1710198000000, Math.floor(Math.random() * 20) + 9.8],
        [1710284400000, Math.floor(Math.random() * 20) + 10.3],
        [1710370800000, Math.floor(Math.random() * 20) + 11.5],
        [1710457200000, Math.floor(Math.random() * 20) + 13],
        [1710543600000, Math.floor(Math.random() * 20) + 15.9],
        [1710630000000, Math.floor(Math.random() * 20) + 14.8],
        [1710716400000, Math.floor(Math.random() * 20) + 16.3],
        [1710802800000, Math.floor(Math.random() * 20) + 16.4],
        [1710889200000, Math.floor(Math.random() * 20) + 16.5],
        [1710975600000, Math.floor(Math.random() * 20) + 16.5],
        [1711062000000, Math.floor(Math.random() * 20) + 15.8],
        [1711148400000, Math.floor(Math.random() * 20) + 16.8],
        [1711234800000, Math.floor(Math.random() * 20) + 16.1],
        [1711321200000, Math.floor(Math.random() * 20) + 12.3],
        [1711407600000, Math.floor(Math.random() * 20) + 10.7],
        [1711494000000, Math.floor(Math.random() * 20) + 10.4],
        [1711580400000, Math.floor(Math.random() * 20) + 9.5],
        [1711666800000, Math.floor(Math.random() * 20) + 15.1],
        [1711753200000, Math.floor(Math.random() * 20) + 16],
        [1711839600000, Math.floor(Math.random() * 20) + 11.6],
        [1711922400000, Math.floor(Math.random() * 20) + 11.5],
        [1712008800000, Math.floor(Math.random() * 20) + 10.6],
        [1712095200000, Math.floor(Math.random() * 20) + 11.8],
        [1712181600000, Math.floor(Math.random() * 20) + 13.6],
        [1712268000000, Math.floor(Math.random() * 20) + 15.9],
        [1712354400000, Math.floor(Math.random() * 20) + 17.2],
        [1712440800000, Math.floor(Math.random() * 20) + 19.5],
        [1712527200000, Math.floor(Math.random() * 20) + 20.4],
        [1712613600000, Math.floor(Math.random() * 20) + 19.5],
        [1712700000000, Math.floor(Math.random() * 20) + 12.8],
        [1712786400000, Math.floor(Math.random() * 20) + 12],
        [1712872800000, Math.floor(Math.random() * 20) + 14.5],
        [1712959200000, Math.floor(Math.random() * 20) + 16.2],
        [1713045600000, Math.floor(Math.random() * 20) + 18.5],
        [1713132000000, Math.floor(Math.random() * 20) + 19.6],
        [1713218400000, Math.floor(Math.random() * 20) + 18.1],
        [1713304800000, Math.floor(Math.random() * 20) + 14.6],
        [1713391200000, Math.floor(Math.random() * 20) + 13.7],
        [1713477600000, Math.floor(Math.random() * 20) + 13],
        [1713564000000, Math.floor(Math.random() * 20) + 13.1],
        [1713650400000, Math.floor(Math.random() * 20) + 14.5],
        [1713736800000, Math.floor(Math.random() * 20) + 14.3],
        [1713823200000, Math.floor(Math.random() * 20) + 13.2],
        [1713909600000, Math.floor(Math.random() * 20) + 9.7],
        [1713996000000, Math.floor(Math.random() * 20) + 10.8],
        [1714082400000, Math.floor(Math.random() * 20) + 11.8],
        [1714168800000, Math.floor(Math.random() * 20) + 14],
        [1714255200000, Math.floor(Math.random() * 20) + 15.5],
        [1714341600000, Math.floor(Math.random() * 20) + 13.3],
        [1714428000000, Math.floor(Math.random() * 20) + 12.4],
        [1714514400000, Math.floor(Math.random() * 20) + 14.6],
        [1714600800000, Math.floor(Math.random() * 20) + 13.1],
        [1714687200000, Math.floor(Math.random() * 20) + 12.4],
        [1714773600000, Math.floor(Math.random() * 20) + 13.9],
        [1714860000000, Math.floor(Math.random() * 20) + 17.2],
        [1714946400000, Math.floor(Math.random() * 20) + 18.2],
        [1715032800000, Math.floor(Math.random() * 20) + 17.5],
        [1715119200000, Math.floor(Math.random() * 20) + 15.5],
        [1715205600000, Math.floor(Math.random() * 20) + 16.5],
        [1715292000000, Math.floor(Math.random() * 20) + 17.7],
        [1715378400000, Math.floor(Math.random() * 20) + 18.9],
        [1715464800000, Math.floor(Math.random() * 20) + 19.2],
        [1715551200000, Math.floor(Math.random() * 20) + 19.6],
        [1715637600000, Math.floor(Math.random() * 20) + 20.2],
        [1715724000000, Math.floor(Math.random() * 20) + 17.5],
        [1715810400000, Math.floor(Math.random() * 20) + 15.9],
        [1715896800000, Math.floor(Math.random() * 20) + 16.3],
        [1715983200000, Math.floor(Math.random() * 20) + 17.3],
        [1716069600000, Math.floor(Math.random() * 20) + 17.7],
        [1716156000000, Math.floor(Math.random() * 20) + 16.2],
        [1716242400000, Math.floor(Math.random() * 20) + 16.8],
        [1716328800000, Math.floor(Math.random() * 20) + 17.3],
        [1716415200000, Math.floor(Math.random() * 20) + 18.1],
        [1716501600000, Math.floor(Math.random() * 20) + 17.9],
        [1716548400000, Math.floor(Math.random() * 20) + 16.2],
      ],
      tooltip: {
        valueSuffix: ' m3/h',
      },
      color: '#fa9704',
    },
    {
      name: 'A4500 NS54',
      type: 'column',
      data: [
        [1696197600000, Math.floor(Math.random() * 10) + 24.5],
        [1696284000000, Math.floor(Math.random() * 10) + 23.3],
        [1696370400000, Math.floor(Math.random() * 10) + 22.1],
        [1696456800000, Math.floor(Math.random() * 10) + 23.3],
        [1696543200000, Math.floor(Math.random() * 10) + 22.8],
        [1696629600000, Math.floor(Math.random() * 10) + 22.5],
        [1696716000000, Math.floor(Math.random() * 10) + 22.3],
        [1696802400000, Math.floor(Math.random() * 10) + 22.2],
        [1696888800000, Math.floor(Math.random() * 10) + 21.7],
        [1696975200000, Math.floor(Math.random() * 10) + 21.2],
        [1697061600000, Math.floor(Math.random() * 10) + 20.7],
        [1697148000000, Math.floor(Math.random() * 10) + 20.2],
        [1697234400000, Math.floor(Math.random() * 10) + 20.5],
        [1697320800000, Math.floor(Math.random() * 10) + 20.9],
        [1697407200000, Math.floor(Math.random() * 10) + 19.9],
        [1697493600000, Math.floor(Math.random() * 10) + 18.5],
        [1697580000000, Math.floor(Math.random() * 10) + 19.2],
        [1697666400000, Math.floor(Math.random() * 10) + 21.4],
        [1697752800000, Math.floor(Math.random() * 10) + 18.2],
        [1697839200000, Math.floor(Math.random() * 10) + 16.2],
        [1697925600000, Math.floor(Math.random() * 10) + 14.3],
        [1698012000000, Math.floor(Math.random() * 10) + 14],
        [1698098400000, Math.floor(Math.random() * 10) + 18.7],
        [1698184800000, Math.floor(Math.random() * 10) + 15.6],
        [1698271200000, Math.floor(Math.random() * 10) + 16.2],
        [1698357600000, Math.floor(Math.random() * 10) + 18.2],
        [1698444000000, Math.floor(Math.random() * 10) + 15.8],
        [1698530400000, Math.floor(Math.random() * 10) + 15.4],
        [1698620400000, Math.floor(Math.random() * 10) + 17],
        [1698706800000, Math.floor(Math.random() * 10) + 14.2],
        [1698793200000, Math.floor(Math.random() * 10) + 12.2],
        [1698879600000, Math.floor(Math.random() * 10) + 12],
        [1698966000000, Math.floor(Math.random() * 10) + 13.7],
        [1699052400000, Math.floor(Math.random() * 10) + 10.5],
        [1699138800000, Math.floor(Math.random() * 10) + 11.8],
        [1699225200000, Math.floor(Math.random() * 10) + 13.8],
        [1699311600000, Math.floor(Math.random() * 10) + 11.8],
        [1699398000000, Math.floor(Math.random() * 10) + 9.3],
        [1699484400000, Math.floor(Math.random() * 10) + 9.1],
        [1699570800000, Math.floor(Math.random() * 10) + 10.9],
        [1699657200000, Math.floor(Math.random() * 10) + 13],
        [1699743600000, Math.floor(Math.random() * 10) + 12.8],
        [1699830000000, Math.floor(Math.random() * 10) + 16.8],
        [1699916400000, Math.floor(Math.random() * 10) + 15.8],
        [1700002800000, Math.floor(Math.random() * 10) + 16.3],
        [1700089200000, Math.floor(Math.random() * 10) + 15.7],
        [1700175600000, Math.floor(Math.random() * 10) + 15.4],
        [1700262000000, Math.floor(Math.random() * 10) + 13.6],
        [1700348400000, Math.floor(Math.random() * 10) + 11.4],
        [1700434800000, Math.floor(Math.random() * 10) + 11.1],
        [1700521200000, Math.floor(Math.random() * 10) + 11.6],
        [1700607600000, Math.floor(Math.random() * 10) + 12.5],
        [1700694000000, Math.floor(Math.random() * 10) + 11.4],
        [1700780400000, Math.floor(Math.random() * 10) + 8],
        [1700866800000, Math.floor(Math.random() * 10) + 8.1],
        [1700953200000, Math.floor(Math.random() * 10) + 8.7],
        [1701039600000, Math.floor(Math.random() * 10) + 7.2],
        [1701126000000, Math.floor(Math.random() * 10) + 8.4],
        [1701212400000, Math.floor(Math.random() * 10) + 12.7],
        [1701298800000, Math.floor(Math.random() * 10) + 11.4],
        [1701385200000, Math.floor(Math.random() * 10) + 12.3],
        [1701471600000, Math.floor(Math.random() * 10) + 11.1],
        [1701558000000, Math.floor(Math.random() * 10) + 8.8],
        [1701644400000, Math.floor(Math.random() * 10) + 5.2],
        [1701730800000, Math.floor(Math.random() * 10) + 7.3],
        [1701817200000, Math.floor(Math.random() * 10) + 7.4],
        [1701903600000, Math.floor(Math.random() * 10) + 6.3],
        [1701990000000, Math.floor(Math.random() * 10) + 5.7],
        [1702076400000, Math.floor(Math.random() * 10) + 9.7],
        [1702162800000, Math.floor(Math.random() * 10) + 8.6],
        [1702249200000, Math.floor(Math.random() * 10) + 10.7],
        [1702335600000, Math.floor(Math.random() * 10) + 10.5],
        [1702422000000, Math.floor(Math.random() * 10) + 9.8],
        [1702508400000, Math.floor(Math.random() * 10) + 11.6],
        [1702594800000, Math.floor(Math.random() * 10) + 9.9],
        [1702681200000, Math.floor(Math.random() * 10) + 8.2],
        [1702767600000, Math.floor(Math.random() * 10) + 6],
        [1702854000000, Math.floor(Math.random() * 10) + 3],
        [1702940400000, Math.floor(Math.random() * 10) + 0.4],
        [1703026800000, Math.floor(Math.random() * 10) + 1.2],
        [1703113200000, Math.floor(Math.random() * 10) + 1.3],
        [1703199600000, Math.floor(Math.random() * 10) + 8.4],
        [1703286000000, Math.floor(Math.random() * 10) + 12.7],
        [1703372400000, Math.floor(Math.random() * 10) + 9],
        [1703458800000, Math.floor(Math.random() * 10) + 4.8],
        [1703545200000, Math.floor(Math.random() * 10) + 4.5],
        [1703631600000, Math.floor(Math.random() * 10) + 1.9],
        [1703718000000, Math.floor(Math.random() * 10) + 1],
        [1703804400000, Math.floor(Math.random() * 10) + 0.9],
        [1703890800000, Math.floor(Math.random() * 10) + 1.8],
        [1703977200000, Math.floor(Math.random() * 10) + 4.2],
        [1704063600000, Math.floor(Math.random() * 10) + 6.1],
        [1704150000000, Math.floor(Math.random() * 10) + 6.7],
        [1704236400000, Math.floor(Math.random() * 10) + 3.9],
        [1704322800000, Math.floor(Math.random() * 10) + 8.1],
        [1704409200000, Math.floor(Math.random() * 10) + 9.2],
        [1704495600000, Math.floor(Math.random() * 10) + 8.7],
        [1704582000000, Math.floor(Math.random() * 10) + 6.9],
        [1704668400000, Math.floor(Math.random() * 10) + 6.2],
        [1704754800000, Math.floor(Math.random() * 10) + 4.7],
        [1704841200000, Math.floor(Math.random() * 10) + 3.8],
        [1704927600000, Math.floor(Math.random() * 10) + 5.6],
        [1705014000000, Math.floor(Math.random() * 10) + 4.6],
        [1705100400000, Math.floor(Math.random() * 10) + 1.9],
        [1705186800000, Math.floor(Math.random() * 10) + 0.4],
        [1705273200000, Math.floor(Math.random() * 10) + 2.1],
        [1705359600000, Math.floor(Math.random() * 10) + 7.3],
        [1705446000000, Math.floor(Math.random() * 10) + 9.6],
        [1705532400000, Math.floor(Math.random() * 10) + 11],
        [1705618800000, Math.floor(Math.random() * 10) + 12.3],
        [1705705200000, Math.floor(Math.random() * 10) + 7.2],
        [1705791600000, Math.floor(Math.random() * 10) + 4.1],
        [1705878000000, Math.floor(Math.random() * 10) + 1.8],
        [1705964400000, Math.floor(Math.random() * 10) + 1.4],
        [1706050800000, Math.floor(Math.random() * 10) + 5.3],
        [1706137200000, Math.floor(Math.random() * 10) + 6.3],
        [1706223600000, Math.floor(Math.random() * 10) + 5.8],
        [1706310000000, Math.floor(Math.random() * 10) + 5.7],
        [1706396400000, Math.floor(Math.random() * 10) + 5.7],
        [1706482800000, Math.floor(Math.random() * 10) + 8.6],
        [1706569200000, Math.floor(Math.random() * 10) + 10.5],
        [1706655600000, Math.floor(Math.random() * 10) + 10.4],
        [1706742000000, Math.floor(Math.random() * 10) + 6.6],
        [1706828400000, Math.floor(Math.random() * 10) + 5.6],
        [1706914800000, Math.floor(Math.random() * 10) + 5.9],
        [1707001200000, Math.floor(Math.random() * 10) + 4.2],
        [1707087600000, Math.floor(Math.random() * 10) + 5.1],
        [1707174000000, Math.floor(Math.random() * 10) + 4.6],
        [1707260400000, Math.floor(Math.random() * 10) + 4.7],
        [1707346800000, Math.floor(Math.random() * 10) + 5.8],
        [1707433200000, Math.floor(Math.random() * 10) + 9.1],
        [1707519600000, Math.floor(Math.random() * 10) + 11],
        [1707606000000, Math.floor(Math.random() * 10) + 10.6],
        [1707692400000, Math.floor(Math.random() * 10) + 8.5],
        [1707778800000, Math.floor(Math.random() * 10) + 9.4],
        [1707865200000, Math.floor(Math.random() * 10) + 10.7],
        [1707951600000, Math.floor(Math.random() * 10) + 11.9],
        [1708038000000, Math.floor(Math.random() * 10) + 14.5],
        [1708124400000, Math.floor(Math.random() * 10) + 10.8],
        [1708210800000, Math.floor(Math.random() * 10) + 11.1],
        [1708297200000, Math.floor(Math.random() * 10) + 10.2],
        [1708383600000, Math.floor(Math.random() * 10) + 12],
        [1708470000000, Math.floor(Math.random() * 10) + 10.4],
        [1708556400000, Math.floor(Math.random() * 10) + 10.4],
        [1708642800000, Math.floor(Math.random() * 10) + 12.2],
        [1708729200000, Math.floor(Math.random() * 10) + 8.9],
        [1708815600000, Math.floor(Math.random() * 10) + 7.5],
        [1708902000000, Math.floor(Math.random() * 10) + 8],
        [1708988400000, Math.floor(Math.random() * 10) + 9.4],
        [1709074800000, Math.floor(Math.random() * 10) + 9.3],
        [1709161200000, Math.floor(Math.random() * 10) + 10.4],
        [1709247600000, Math.floor(Math.random() * 10) + 9.6],
        [1709334000000, Math.floor(Math.random() * 10) + 9.6],
        [1709420400000, Math.floor(Math.random() * 10) + 10.4],
        [1709506800000, Math.floor(Math.random() * 10) + 7.7],
        [1709593200000, Math.floor(Math.random() * 10) + 8.1],
        [1709679600000, Math.floor(Math.random() * 10) + 9.7],
        [1709766000000, Math.floor(Math.random() * 10) + 8.6],
        [1709852400000, Math.floor(Math.random() * 10) + 10.5],
        [1709938800000, Math.floor(Math.random() * 10) + 10],
        [1710025200000, Math.floor(Math.random() * 10) + 8.3],
        [1710111600000, Math.floor(Math.random() * 10) + 8.1],
        [1710198000000, Math.floor(Math.random() * 10) + 9.8],
        [1710284400000, Math.floor(Math.random() * 10) + 10.3],
        [1710370800000, Math.floor(Math.random() * 10) + 11.5],
        [1710457200000, Math.floor(Math.random() * 10) + 13],
        [1710543600000, Math.floor(Math.random() * 10) + 15.9],
        [1710630000000, Math.floor(Math.random() * 10) + 14.8],
        [1710716400000, Math.floor(Math.random() * 10) + 16.3],
        [1710802800000, Math.floor(Math.random() * 10) + 16.4],
        [1710889200000, Math.floor(Math.random() * 10) + 16.5],
        [1710975600000, Math.floor(Math.random() * 10) + 16.5],
        [1711062000000, Math.floor(Math.random() * 10) + 15.8],
        [1711148400000, Math.floor(Math.random() * 10) + 16.8],
        [1711234800000, Math.floor(Math.random() * 10) + 16.1],
        [1711321200000, Math.floor(Math.random() * 10) + 12.3],
        [1711407600000, Math.floor(Math.random() * 10) + 10.7],
        [1711494000000, Math.floor(Math.random() * 10) + 10.4],
        [1711580400000, Math.floor(Math.random() * 10) + 9.5],
        [1711666800000, Math.floor(Math.random() * 10) + 15.1],
        [1711753200000, Math.floor(Math.random() * 10) + 16],
        [1711839600000, Math.floor(Math.random() * 10) + 11.6],
        [1711922400000, Math.floor(Math.random() * 10) + 11.5],
        [1712008800000, Math.floor(Math.random() * 10) + 10.6],
        [1712095200000, Math.floor(Math.random() * 10) + 11.8],
        [1712181600000, Math.floor(Math.random() * 10) + 13.6],
        [1712268000000, Math.floor(Math.random() * 10) + 15.9],
        [1712354400000, Math.floor(Math.random() * 10) + 17.2],
        [1712440800000, Math.floor(Math.random() * 10) + 19.5],
        [1712527200000, Math.floor(Math.random() * 10) + 20.4],
        [1712613600000, Math.floor(Math.random() * 10) + 19.5],
        [1712700000000, Math.floor(Math.random() * 10) + 12.8],
        [1712786400000, Math.floor(Math.random() * 10) + 12],
        [1712872800000, Math.floor(Math.random() * 10) + 14.5],
        [1712959200000, Math.floor(Math.random() * 10) + 16.2],
        [1713045600000, Math.floor(Math.random() * 10) + 18.5],
        [1713132000000, Math.floor(Math.random() * 10) + 19.6],
        [1713218400000, Math.floor(Math.random() * 10) + 18.1],
        [1713304800000, Math.floor(Math.random() * 10) + 14.6],
        [1713391200000, Math.floor(Math.random() * 10) + 13.7],
        [1713477600000, Math.floor(Math.random() * 10) + 13],
        [1713564000000, Math.floor(Math.random() * 10) + 13.1],
        [1713650400000, Math.floor(Math.random() * 10) + 14.5],
        [1713736800000, Math.floor(Math.random() * 10) + 14.3],
        [1713823200000, Math.floor(Math.random() * 10) + 13.2],
        [1713909600000, Math.floor(Math.random() * 10) + 9.7],
        [1713996000000, Math.floor(Math.random() * 10) + 10.8],
        [1714082400000, Math.floor(Math.random() * 10) + 11.8],
        [1714168800000, Math.floor(Math.random() * 10) + 14],
        [1714255200000, Math.floor(Math.random() * 10) + 15.5],
        [1714341600000, Math.floor(Math.random() * 10) + 13.3],
        [1714428000000, Math.floor(Math.random() * 10) + 12.4],
        [1714514400000, Math.floor(Math.random() * 10) + 14.6],
        [1714600800000, Math.floor(Math.random() * 10) + 13.1],
        [1714687200000, Math.floor(Math.random() * 10) + 12.4],
        [1714773600000, Math.floor(Math.random() * 10) + 13.9],
        [1714860000000, Math.floor(Math.random() * 10) + 17.2],
        [1714946400000, Math.floor(Math.random() * 10) + 18.2],
        [1715032800000, Math.floor(Math.random() * 10) + 17.5],
        [1715119200000, Math.floor(Math.random() * 10) + 15.5],
        [1715205600000, Math.floor(Math.random() * 10) + 16.5],
        [1715292000000, Math.floor(Math.random() * 10) + 17.7],
        [1715378400000, Math.floor(Math.random() * 10) + 18.9],
        [1715464800000, Math.floor(Math.random() * 10) + 19.2],
        [1715551200000, Math.floor(Math.random() * 10) + 19.6],
        [1715637600000, Math.floor(Math.random() * 10) + 20.2],
        [1715724000000, Math.floor(Math.random() * 10) + 17.5],
        [1715810400000, Math.floor(Math.random() * 10) + 15.9],
        [1715896800000, Math.floor(Math.random() * 10) + 16.3],
        [1715983200000, Math.floor(Math.random() * 10) + 17.3],
        [1716069600000, Math.floor(Math.random() * 10) + 17.7],
        [1716156000000, Math.floor(Math.random() * 10) + 16.2],
        [1716242400000, Math.floor(Math.random() * 10) + 16.8],
        [1716328800000, Math.floor(Math.random() * 10) + 17.3],
        [1716415200000, Math.floor(Math.random() * 10) + 18.1],
        [1716501600000, Math.floor(Math.random() * 10) + 17.9],
      ],
      tooltip: {
        valueSuffix: ' ºC',
      },
      color: '#d0c781',
    },

    {
      yAxis: 1,
      name: 'BIT-DIN',
      type: 'spline',
      data: [
        [1696197600000, Math.floor(Math.random() * 10) + 24.5],
        [1696284000000, Math.floor(Math.random() * 10) + 23.3],
        [1696370400000, Math.floor(Math.random() * 10) + 22.1],
        [1696456800000, 23.3],
        [1696543200000, 22.8],
        [1696629600000, 22.5],
        [1696716000000, 22.3],
        [1696802400000, 22.2],
        [1696888800000, 21.7],
        [1696975200000, 21.2],
        [1697061600000, 20.7],
        [1697148000000, 20.2],
        [1697234400000, 20.5],
        [1697320800000, 20.9],
        [1697407200000, 19.9],
        [1697493600000, 18.5],
        [1697580000000, 19.2],
        [1697666400000, 21.4],
        [1697752800000, 18.2],
        [1697839200000, 16.2],
        [1697925600000, 14.3],
        [1698012000000, 14],
        [1698098400000, 18.7],
        [1698184800000, 15.6],
        [1698271200000, 16.2],
        [1698357600000, 18.2],
        [1698444000000, 15.8],
        [1698530400000, 15.4],
        [1698620400000, 17],
        [1698706800000, 14.2],
        [1698793200000, 12.2],
        [1698879600000, 12],
        [1698966000000, 13.7],
        [1699052400000, 10.5],
        [1699138800000, 11.8],
        [1699225200000, 13.8],
        [1699311600000, 11.8],
        [1699398000000, 9.3],
        [1699484400000, 9.1],
        [1699570800000, 10.9],
        [1699657200000, 13],
        [1699743600000, 12.8],
        [1699830000000, 16.8],
        [1699916400000, 15.8],
        [1700002800000, 16.3],
        [1700089200000, 15.7],
        [1700175600000, 15.4],
        [1700262000000, 13.6],
        [1700348400000, 11.4],
        [1700434800000, 11.1],
        [1700521200000, 11.6],
        [1700607600000, 12.5],
        [1700694000000, 11.4],
        [1700780400000, 8],
        [1700866800000, 8.1],
        [1700953200000, 8.7],
        [1701039600000, 7.2],
        [1701126000000, 8.4],
        [1701212400000, 12.7],
        [1701298800000, 11.4],
        [1701385200000, 12.3],
        [1701471600000, 11.1],
        [1701558000000, 8.8],
        [1701644400000, 5.2],
        [1701730800000, 7.3],
        [1701817200000, 7.4],
        [1701903600000, 6.3],
        [1701990000000, 5.7],
        [1702076400000, 9.7],
        [1702162800000, 8.6],
        [1702249200000, 10.7],
        [1702335600000, 10.5],
        [1702422000000, 9.8],
        [1702508400000, 11.6],
        [1702594800000, 9.9],
        [1702681200000, 8.2],
        [1702767600000, 6],
        [1702854000000, 3],
        [1702940400000, 0.4],
        [1703026800000, 1.2],
        [1703113200000, 1.3],
        [1703199600000, 8.4],
        [1703286000000, 12.7],
        [1703372400000, 9],
        [1703458800000, 4.8],
        [1703545200000, 4.5],
        [1703631600000, 1.9],
        [1703718000000, 1],
        [1703804400000, 0.9],
        [1703890800000, 1.8],
        [1703977200000, 4.2],
        [1704063600000, 6.1],
        [1704150000000, 6.7],
        [1704236400000, 3.9],
        [1704322800000, 8.1],
        [1704409200000, 9.2],
        [1704495600000, 8.7],
        [1704582000000, 6.9],
        [1704668400000, 6.2],
        [1704754800000, 4.7],
        [1704841200000, 3.8],
        [1704927600000, 5.6],
        [1705014000000, 4.6],
        [1705100400000, 1.9],
        [1705186800000, 0.4],
        [1705273200000, 2.1],
        [1705359600000, 7.3],
        [1705446000000, 9.6],
        [1705532400000, 11],
        [1705618800000, 12.3],
        [1705705200000, 7.2],
        [1705791600000, 4.1],
        [1705878000000, 1.8],
        [1705964400000, 1.4],
        [1706050800000, 5.3],
        [1706137200000, 6.3],
        [1706223600000, 5.8],
        [1706310000000, 5.7],
        [1706396400000, 5.7],
        [1706482800000, 8.6],
        [1706569200000, 10.5],
        [1706655600000, 10.4],
        [1706742000000, 6.6],
        [1706828400000, 5.6],
        [1706914800000, 5.9],
        [1707001200000, 4.2],
        [1707087600000, 5.1],
        [1707174000000, 4.6],
        [1707260400000, 4.7],
        [1707346800000, 5.8],
        [1707433200000, 9.1],
        [1707519600000, 11],
        [1707606000000, 10.6],
        [1707692400000, 8.5],
        [1707778800000, 9.4],
        [1707865200000, 10.7],
        [1707951600000, 11.9],
        [1708038000000, 14.5],
        [1708124400000, 10.8],
        [1708210800000, 11.1],
        [1708297200000, 10.2],
        [1708383600000, 12],
        [1708470000000, 10.4],
        [1708556400000, 10.4],
        [1708642800000, 12.2],
        [1708729200000, 8.9],
        [1708815600000, 7.5],
        [1708902000000, 8],
        [1708988400000, 9.4],
        [1709074800000, 9.3],
        [1709161200000, 10.4],
        [1709247600000, 9.6],
        [1709334000000, 9.6],
        [1709420400000, 10.4],
        [1709506800000, 7.7],
        [1709593200000, 8.1],
        [1709679600000, 9.7],
        [1709766000000, 8.6],
        [1709852400000, 10.5],
        [1709938800000, 10],
        [1710025200000, 8.3],
        [1710111600000, 8.1],
        [1710198000000, 9.8],
        [1710284400000, 10.3],
        [1710370800000, 11.5],
        [1710457200000, 13],
        [1710543600000, 15.9],
        [1710630000000, 14.8],
        [1710716400000, 16.3],
        [1710802800000, 16.4],
        [1710889200000, 16.5],
        [1710975600000, 16.5],
        [1711062000000, 15.8],
        [1711148400000, 16.8],
        [1711234800000, 16.1],
        [1711321200000, 12.3],
        [1711407600000, 10.7],
        [1711494000000, 10.4],
        [1711580400000, 9.5],
        [1711666800000, 15.1],
        [1711753200000, 16],
        [1711839600000, 11.6],
        [1711922400000, 11.5],
        [1712008800000, 10.6],
        [1712095200000, 11.8],
        [1712181600000, 13.6],
        [1712268000000, 15.9],
        [1712354400000, 17.2],
        [1712440800000, 19.5],
        [1712527200000, 20.4],
        [1712613600000, 19.5],
        [1712700000000, 12.8],
        [1712786400000, 12],
        [1712872800000, 14.5],
        [1712959200000, 16.2],
        [1713045600000, 18.5],
        [1713132000000, 19.6],
        [1713218400000, 18.1],
        [1713304800000, 14.6],
        [1713391200000, 13.7],
        [1713477600000, 13],
        [1713564000000, 13.1],
        [1713650400000, 14.5],
        [1713736800000, 14.3],
        [1713823200000, 13.2],
        [1713909600000, 9.7],
        [1713996000000, 10.8],
        [1714082400000, 11.8],
        [1714168800000, 14],
        [1714255200000, 15.5],
        [1714341600000, 13.3],
        [1714428000000, 12.4],
        [1714514400000, 14.6],
        [1714600800000, 13.1],
        [1714687200000, 12.4],
        [1714773600000, 13.9],
        [1714860000000, 17.2],
        [1714946400000, 18.2],
        [1715032800000, 17.5],
        [1715119200000, 15.5],
        [1715205600000, 16.5],
        [1715292000000, 17.7],
        [1715378400000, 18.9],
        [1715464800000, 19.2],
        [1715551200000, 19.6],
        [1715637600000, 20.2],
        [1715724000000, 17.5],
        [1715810400000, 15.9],
        [1715896800000, 16.3],
        [1715983200000, 17.3],
        [1716069600000, 17.7],
        [1716156000000, 16.2],
        [1716242400000, 16.8],
        [1716328800000, 17.3],
        [1716415200000, 18.1],
        [1716501600000, 17.9],
        [1716548400000, 16.2],
      ],
      tooltip: {
        valueSuffix: ' m3/h',
      },
      color: '#3D81B3',
    },
  ],
  xAxis: {
    type: 'datetime',
    width: 5,
  },
  yAxis: [
    {
      maxPadding: 0,
      plotBands: [
        {
          color: '#8db6f750',
          from: 0,
          to: 25,
        },
        {
          color: '#8db6f730',
          from: 25,
          to: 40,
        },
        {
          color: '#8db6f710',
          from: 40,
          to: 50,
        },
      ],
      title: {
        text: 'ºC',
      },

      opposite: false,
    },
    {
      title: {
        text: 'm3/h',
      },

      opposite: true,
    },
    {
      title: {
        text: 'l/s',
      },

      opposite: false,
    },
  ],
};
export const mockData = [
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [0.8777701705932618, 41.63956522945116],
    },
    properties: {
      icon: 'device',
      layerType: 'device',
      iconBgColor: 'var(--vegga-color-green-grass-500)',
      iconBorderColor: 'var(--vegga-color-green-grass-700)',
      deviceId: 1,
      hasTooltip: true,
      layerName: 'NS52',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [0.8663320541381837, 41.63161782584505],
    },
    properties: {
      icon: 'pdc-climate',
      layerType: 'meteoStation',
      iconBgColor: 'var(--vegga-color-green-grass-500)',
      iconBorderColor: 'var(--vegga-color-green-grass-700)',
      deviceId: 1,
      hasTooltip: true,
      layerName: 'meteoStation',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [0.8733701705932618, 41.63956522945116],
          [0.86942195892334, 41.6394048676045],
          [0.8663320541381837, 41.64161782584505],
          [0.8671903610229492, 41.64283652402002],
          [0.8702373504638673, 41.64456831861937],
          [0.8704948425292969, 41.64296480669764],
          [0.872490406036377, 41.64315723023523],
        ],
      ],
    },
    properties: {
      hasTooltip: true,
      layerType: 'plot',
      plotId: 1,
      areaBgColor: 'var(--vegga-color-green-grass-700)',
      color: '#D0C781',
      layerName: 'S2 - Sec. 2',
      minZoomToShow: 14,
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [0.8612899536132814, 41.64278841795006],
    },
    properties: {
      icon: 'device',
      layerType: 'device',
      iconBgColor: 'var(--vegga-color-green-soil-500)',
      iconBorderColor: 'var(--vegga-color-green-soil-700)',
      deviceId: 2,
      hasTooltip: true,
      layerName: 'Badina',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [0.8668899536132814, 41.64278841795006],
          [0.8645296096801758, 41.64346189966119],
          [0.8649373054504396, 41.64464849316794],
          [0.8675122261047363, 41.64450417890867],
          [0.8700227737426759, 41.64458435353706],
        ],
      ],
    },
    properties: {
      hasTooltip: true,
      layerType: 'plot',
      plotId: 2,
      areaBgColor: 'var(--vegga-color-yellow-sun-600)',
      color: '#D0C781',
      layerName: 'S1 - Sec. 1',
      minZoomToShow: 14,
    },
  },
];

export const mockPdC = [
  {
    title: 'Temperatura',
    icon: 'temperature-three-quarters',
    info: [
      {
        label: 'Temp. actual',
        value: '15 ºC',
      },
      {
        label: 'Temp. max',
        value: '23 ºC',
      },
      {
        label: 'Temp. min',
        value: '14 ºC',
      },
    ],
  },
  {
    title: 'Pluviómetro',
    icon: 'cloud-rain',
    info: [
      {
        label: 'Temp. actual',
        value: '15 ºC',
      },
    ],
  },
  {
    title: 'Anemómetro',
    icon: 'wind',
    info: [
      {
        label: 'Temp. actual',
        value: '15 ºC',
      },
    ],
  },
  {
    title: 'Radiación',
    icon: 'sun-plant-wilt',
    info: [
      {
        label: 'Temp. actual',
        value: '15 ºC',
      },
    ],
  },
];

export const mockedDeviceEl1 = {
  type: 'device',
  deviceName: 'NS52',
  code: '75-00052',
  lastComm: '14:01 12/02/2024',
  headers: [
    {
      id: 1,
      name: 'Cabezal 1',
      info: [
        {
          name: 'Fert. 1',
          value: '30 L',
          status: 'success',
          active: true,
        },
        {
          name: 'Fert. 2',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'Motor 1',
          value: null,
          status: 'error',
          active: true,
        },
        {
          name: 'Filtro 1',
          value: '0 m3',
          status: 'success',
          active: true,
        },
        {
          name: 'Motor 2',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'Filtro 2',
          value: null,
          status: 'blue-grey-200',
        },
      ],
      sensors: [
        {
          name: 'SA1 - Temp. 1',
          value: '15 °C',
          status: 'success',
          active: true,
        },
        {
          name: 'SC5- S. Con. 5',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'SC15 -',
          value: null,
          status: 'success',
          active: true,
        },
        {
          name: 'SC4 -',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'SC3 - C.Gen.',
          value: '15 m3/h',
          status: 'error',
          active: true,
        },
        {
          name: 'SA3 - Aigua neta',
          value: '30%',
          status: 'success',
          active: true,
        },
        {
          name: 'SA5 - S. Ana. 5',
          value: null,
          status: 'blue-grey-200',
        },

        {
          name: 'SC3-C. Gen.',
          value: null,
          status: 'blue-grey-200',
        },
      ],
      modules: [
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'success', 'success', 'success', 'success', 'success'],
          signal: '30%',
        },
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'success', 'success', 'error', 'error', 'error'],
          signal: null,
        },
      ],
      irrigation: [
        {
          status: 'success',
          name: 'P1 - Prog 1.',
          sectorName: 'S1 - Sec. 1',
          programName: 'Prog 1 - SP 1',
          irrigationTime: '20:00 mm:ss',
          amount: '20 m3/h',
        },
        {
          status: 'error',
          name: 'P1 - Prog 1.',
          sectorName: 'S23 - Sec. 23',
          programName: 'Prog 3 - SP 2',
          irrigationTime: '30 m3',
          amount: '30 l/h',
        },
      ],
    },
    {
      id: 2,
      name: 'Cabezal 2',
      info: [
        {
          name: 'Fert. 1',
          value: null,
          status: 'error',
        },
        {
          name: 'Motor 1',
          value: null,
          status: 'error',
        },
        {
          name: 'Filtro 1',
          value: '15 m3',
          status: 'success',
        },
      ],
      sensors: [
        {
          name: 'SA1 - Temp. 1',
          value: '22 °C',
          status: 'success',
        },
        {
          name: 'SC15 -',
          value: '10 m/3',
          status: 'success',
        },
        {
          name: 'SC3 - C.Gen.',
          value: '200 m3/h',
          status: 'success',
        },
        {
          name: 'SA2 - Nivell Bassa',
          value: '50%',
          status: 'success',
        },
      ],
      modules: [
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'error', 'error', 'error', 'success', 'success'],
          signal: '30%',
        },
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'error', 'error', 'success', 'success', 'error'],
          signal: null,
        },
      ],
    },
    {
      id: 3,
      name: 'Cabezal 3',
    },
    {
      id: 4,
      name: 'Cabezal 4',
    },
  ],
};

export const mockedDeviceEl2 = {
  type: 'device',
  deviceName: 'Badina',
  code: '12-00024',
  lastComm: '11:01 12/02/2024',
  headers: [
    {
      id: 1,
      name: 'Cabezal 1',
      info: [
        {
          name: 'Fert. 1',
          value: '30 L',
          status: 'success',
          active: true,
        },
        {
          name: 'Fert. 2',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'Motor 1',
          value: null,
          status: 'error',
          active: true,
        },
        {
          name: 'Filtro 1',
          value: '0 m3',
          status: 'success',
          active: true,
        },
        {
          name: 'Motor 2',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'Filtro 2',
          value: null,
          status: 'blue-grey-200',
        },
      ],
      sensors: [
        {
          name: 'SA1 - Temp. 1',
          value: '15 °C',
          status: 'success',
          active: true,
        },
        {
          name: 'SC5- S. Con. 5',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'SC15 -',
          value: null,
          status: 'success',
          active: true,
        },
        {
          name: 'SC4 -',
          value: null,
          status: 'blue-grey-200',
        },
        {
          name: 'SC3 - C.Gen.',
          value: '15 m3/h',
          status: 'error',
          active: true,
        },
        {
          name: 'SA3 - Aigua neta',
          value: '30%',
          status: 'success',
          active: true,
        },
        {
          name: 'SA5 - S. Ana. 5',
          value: null,
          status: 'blue-grey-200',
        },

        {
          name: 'SC3-C. Gen.',
          value: null,
          status: 'blue-grey-200',
        },
      ],
      modules: [
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'success', 'success', 'success', 'success', 'success'],
          signal: '30%',
        },
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'success', 'success', 'error', 'error', 'error'],
          signal: null,
        },
      ],
      irrigation: [
        {
          status: 'error',
          name: 'P10 - Prog 10.',
          sectorName: 'S10 - Sec. 10',
          programName: 'Prog 1 - SP 1',
          irrigationTime: '12:00 mm:ss',
          amount: '210 m3/h',
        },
        {
          status: 'error',
          name: 'P1 - Prog 1.',
          sectorName: 'S23 - Sec. 23',
          programName: 'Prog 3 - SP 2',
          irrigationTime: '30 m3',
          amount: '30 l/h',
        },
      ],
    },
    {
      id: 2,
      name: 'Cabezal 2',
      info: [
        {
          name: 'Fert. 1',
          value: null,
          status: 'error',
        },
        {
          name: 'Motor 1',
          value: null,
          status: 'error',
        },
        {
          name: 'Filtro 1',
          value: '15 m3',
          status: 'success',
        },
      ],
      sensors: [
        {
          name: 'SA1 - Temp. 1',
          value: '22 °C',
          status: 'success',
        },
        {
          name: 'SC15 -',
          value: '10 m/3',
          status: 'success',
        },
        {
          name: 'SC3 - C.Gen.',
          value: '200 m3/h',
          status: 'success',
        },
        {
          name: 'SA2 - Nivell Bassa',
          value: '50%',
          status: 'success',
        },
      ],
      modules: [
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'error', 'error', 'error', 'success', 'success'],
          signal: '30%',
        },
        {
          moduleName: 'AgroBeeL - 1',
          lastComm: ['success', 'error', 'error', 'success', 'success', 'error'],
          signal: null,
        },
      ],
      irrigation: [
        {
          status: 'error',
          name: 'P10 - Prog 10.',
          sectorName: 'S10 - Sec. 10',
          programName: 'Prog 1 - SP 1',
          irrigationTime: '12:00 mm:ss',
          amount: '210 m3/h',
        },
        {
          status: 'error',
          name: 'P1 - Prog 1.',
          sectorName: 'S23 - Sec. 23',
          programName: 'Prog 3 - SP 2',
          irrigationTime: '30 m3',
          amount: '30 l/h',
        },
      ],
    },
    {
      id: 3,
      name: 'Cabezal 3',
    },
    {
      id: 4,
      name: 'Cabezal 4',
    },
  ],
};

export const mockedPlotEl = {
  type: 'plot',
  deviceName: 'S2 - Sec. 2',
  code: 'Trigo',
  lastComm: '14:01 12/02/2024',
  status: 'No riego',
  crop: 'Trigo',
  area: '10.000m2',
};

export const mockedMeteoStationEl = {
  type: 'meteoStation',
  deviceName: 'Estación meteo',
  code: '66-12385',
  data: [
    {
      title: 'Temperatura',
      icon: 'temperature-three-quarters',
      info: [
        {
          label: 'Temp. actual',
          value: '15 ºC',
        },
        {
          label: 'Temp. max',
          value: '23 ºC',
        },
        {
          label: 'Temp. min',
          value: '14 ºC',
        },
      ],
    },
    {
      title: 'Pluviómetro',
      icon: 'cloud-rain',
      info: [
        {
          label: 'Temp. actual',
          value: '15 ºC',
        },
      ],
    },
    {
      title: 'Anemómetro',
      icon: 'wind',
      info: [
        {
          label: 'Temp. actual',
          value: '15 ºC',
        },
      ],
    },
    {
      title: 'Radiación',
      icon: 'sun-plant-wilt',
      info: [
        {
          label: 'Temp. actual',
          value: '15 ºC',
        },
      ],
    },
  ],
};

export const mockMeteoDetail = [
  {
    title: 'Temperatura',
    icon: 'temperature-three-quarters',
    data: graphData,
  },
  // {
  //   title: 'Pluviómetro',
  //   icon: 'cloud-rain',
  //   data: this.graphData
  // }
];
