import { Observable } from 'rxjs';
import { Inject, Injectable } from '../../di';
import { VeggaResponse } from '../../http/veggaResponse';
import {
  ControlPointDetailItem,
  ExploitationControlPointAntiFrostSurveillanceResponse,
  ExploitationControlPointAvailableWaterResponse,
  ExploitationControlPointDewPointResponse,
  ExploitationControlPointDpvResponse,
  ExploitationControlPointEtoResponse,
  ExploitationControlPointHistoryItem,
  ExploitationsApi,
  GetControlPointDataHistoryRequest,
} from '@vegga-api-clients/irrigation-control-service';
import { environment } from '../../../environments/environment';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaFacade } from '../vegga-facade/vegga-facade';
import { handleResponse } from '../common.facade';
import { HttpErrorResponse } from '../../http';

@Injectable('agronomicMonitoringFacade')
export class AgronomicMonitoringFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private controlPointsDataResponse = new VeggaResponse<ControlPointDetailItem[]>();

  private controlPointHistory = new VeggaResponse<ExploitationControlPointHistoryItem[]>();
  private controlPointETOHistory = new VeggaResponse<ExploitationControlPointEtoResponse>();
  private controlPointDPVHistory = new VeggaResponse<ExploitationControlPointDpvResponse>();
  private controlPointDewPointHistory = new VeggaResponse<ExploitationControlPointDewPointResponse>();
  private controlPointAvailableWaterHistory = new VeggaResponse<ExploitationControlPointAvailableWaterResponse>();
  private controlPointFrostSurveillanceHistory = new VeggaResponse<ExploitationControlPointAntiFrostSurveillanceResponse>();
  private controlPointByIdResponse = new VeggaResponse<ControlPointDetailItem, HttpErrorResponse>();

  private exploitationsApi: ExploitationsApi;
  private loader = new VeggaLoader();

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get controlPointsData$(): Observable<ControlPointDetailItem[]> {
    return this.controlPointsDataResponse.value$;
  }

  get controlPointById$(): Observable<ControlPointDetailItem> {
    return this.controlPointByIdResponse.value$;
  }

  get controlPointByIdError$(): Observable<HttpErrorResponse> {
    return this.controlPointByIdResponse.error$;
  }

  get controlPointHistoryData$(): Observable<ExploitationControlPointHistoryItem[]> {
    return this.controlPointHistory.value$;
  }

  get controlPointETOHistoryData$(): Observable<ExploitationControlPointEtoResponse> {
    return this.controlPointETOHistory.value$;
  }

  get controlPointDPVHistoryData$(): Observable<ExploitationControlPointDpvResponse> {
    return this.controlPointDPVHistory.value$;
  }

  get controlPointDewPointHistoryData$(): Observable<ExploitationControlPointDewPointResponse> {
    return this.controlPointDewPointHistory.value$;
  }

  get controlPointAvailableWaterHistoryData$(): Observable<ExploitationControlPointAvailableWaterResponse> {
    return this.controlPointAvailableWaterHistory.value$;
  }

  get controlPointFrostSurveillanceHistoryData$(): Observable<ExploitationControlPointAntiFrostSurveillanceResponse> {
    return this.controlPointFrostSurveillanceHistory.value$;
  }

  constructor() {
    this.exploitationsApi = new ExploitationsApi();
    this.exploitationsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  loadControlPoints() {
    const req$ = this.exploitationsApi.getAllControlPoints();

    const subscription = handleResponse(req$, this.controlPointsDataResponse).subscribe({
      next: agronomicMonitoring => {
        this.controlPointsDataResponse.set(agronomicMonitoring);
      },
      error: err => {
        this.controlPointsDataResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointById(controlPointId: number) {
    const req$ = this.exploitationsApi.getControlPoint({ id: controlPointId });

    const subscription = handleResponse(req$, this.controlPointByIdResponse).subscribe({
      next: ckeckpoint => {
        this.controlPointByIdResponse.set(ckeckpoint);
      },
      error: err => {
        this.controlPointByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointHistory(params: GetControlPointDataHistoryRequest) {
    const req$ = this.exploitationsApi.getControlPointDataHistory({ id: params.id, from: params.from, to: params.to, minAggregation: params.minAggregation });

    const subscription = handleResponse(req$, this.controlPointHistory).subscribe({
      next: history => {
        this.controlPointHistory.set(history);
      },
      error: err => {
        this.controlPointHistory.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointETOHistory(params: GetControlPointDataHistoryRequest) {
    const req$ = this.exploitationsApi.getControlPointEto({ id: params.id, from: params.from, to: params.to, minAggregation: params.minAggregation });

    const subscription = handleResponse(req$, this.controlPointETOHistory).subscribe({
      next: history => {
        this.controlPointETOHistory.set(history);
      },
      error: err => {
        this.controlPointETOHistory.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointDPVHistory(params: GetControlPointDataHistoryRequest) {
    const req$ = this.exploitationsApi.getControlPointDpv({ id: params.id, from: params.from, to: params.to, minAggregation: params.minAggregation });

    const subscription = handleResponse(req$, this.controlPointDPVHistory).subscribe({
      next: history => {
        this.controlPointDPVHistory.set(history);
      },
      error: err => {
        this.controlPointDPVHistory.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }
  loadControlPointAvailableWaterHistory(params: GetControlPointDataHistoryRequest) {
    const req$ = this.exploitationsApi.getControlPointAvailableWater({ id: params.id, from: params.from, to: params.to, minAggregation: params.minAggregation });

    const subscription = handleResponse(req$, this.controlPointAvailableWaterHistory).subscribe({
      next: history => {
        this.controlPointAvailableWaterHistory.set(history);
      },
      error: err => {
        this.controlPointAvailableWaterHistory.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }
  loadControlPointDewPointHistory(params: GetControlPointDataHistoryRequest) {
    const req$ = this.exploitationsApi.getControlPointDewPoint({ id: params.id, from: params.from, to: params.to, minAggregation: params.minAggregation });

    const subscription = handleResponse(req$, this.controlPointDewPointHistory).subscribe({
      next: history => {
        this.controlPointDewPointHistory.set(history);
      },
      error: err => {
        this.controlPointDewPointHistory.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointFrostSurveillanceHistory(params: GetControlPointDataHistoryRequest) {
    const req$ = this.exploitationsApi.getControlPointAntiFrostSurveillance({ id: params.id, from: params.from, to: params.to, minAggregation: params.minAggregation });

    const subscription = handleResponse(req$, this.controlPointFrostSurveillanceHistory).subscribe({
      next: history => {
        this.controlPointFrostSurveillanceHistory.set(history);
      },
      error: err => {
        this.controlPointFrostSurveillanceHistory.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  clearControlPointAvailableWaterHistoryResponse(): void {
    this.controlPointAvailableWaterHistory.clearValue();
    this.controlPointFrostSurveillanceHistory.clearValue();
  }

  clearControlPointByIdResponse(): void {
    this.controlPointByIdResponse.clear();
  }

  clearControlPointByIdError(): void {
    this.controlPointByIdResponse.clearError();
  }
}
