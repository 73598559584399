import { User } from '@vegga-types/user';
import { SessionStore } from '../models/session.store';

export class UsersStore extends SessionStore<User[]> {

    private static instance: UsersStore;

    private constructor(storeName: string){
        super(storeName);
    }

    static getInstance(): UsersStore {
        if (!UsersStore.instance) {
            UsersStore.instance = new UsersStore('users_store');
        }
        return UsersStore.instance;
    }

    storeUser(users: User[]) {
        this.store(users);
    }

    hasUsers(): boolean {
        return this.get() && this.get().length > 0;
    }

}