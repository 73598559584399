import { container } from './container';

export function Inject(key: string) {
  return (classInstance: any, propertyName: string) =>
    Object.defineProperty(classInstance, propertyName, {
      get: () => container.resolve(key),
      enumerable: true,
      configurable: true,
    });
}
