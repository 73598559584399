import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { DeviceType } from '@vegga-api-clients/irrigation-control-service';

@Injectable('fertilizersFacade')
export class FertilizersFacade {
  @Inject('httpClient') private httpClient: HttpClient;

  private fertConfigResponse = new VeggaResponse();
  private fertListResponse = new VeggaResponse();
  private fertilizerResponse = new VeggaResponse();

  get fertilizer(): Observable<any> {
    return this.fertilizerResponse.value$;
  }

  get fertilizerError(): Observable<any> {
    return this.fertilizerResponse.error$;
  }

  constructor() {}

  loadFertilizer(deviceId: number, deviceType: DeviceType) {
    const req$ = this.httpClient.get<any>(`${environment.API_IRRIGATION_CONTROL_ENDPOINT}/devices/${deviceType}/${deviceId}/fertilizers`, {});

    handleResponse(req$, this.fertilizerResponse).subscribe({
      next: (fert) => { this.fertilizerResponse.set(fert) },
      error: (err) => { this.fertilizerResponse.setError(err, {}) },
    });
  }
}
