import { DataLayer, InternalDataLayer, PageDataLayer, UserDataLayer } from "@vegga-types/data-layer";
import { Store } from "../models/store";

export class DataLayerStore extends Store<DataLayer> {
  private static instance: DataLayerStore;

  private internalDataLayer: InternalDataLayer;
  private userDataLayer: UserDataLayer;
  private pageDataLayer: PageDataLayer;

  dataLayer: DataLayer;

  private constructor() {
    super();
  }

  static getInstance(): DataLayerStore {
    if (!DataLayerStore.instance) {
      DataLayerStore.instance = new DataLayerStore();
    }
    return DataLayerStore.instance;
  }

  storeDataLayer(dataLayer: DataLayer) {
    this.store(dataLayer);
  }

  setInternalDataLayer(internalDataLayer: InternalDataLayer) {
    this.internalDataLayer = internalDataLayer;

    this.dataLayer = { ...this.dataLayer, internal: this.internalDataLayer };
  }

  setUserDataLayer(userDataLayer: UserDataLayer) {
    this.userDataLayer = userDataLayer;
    this.dataLayer = { ...this.dataLayer, user: this.userDataLayer };
  }

  setPageDataLayer(pageDataLayer: PageDataLayer) {
    this.pageDataLayer = pageDataLayer;
    this.dataLayer = { ...this.dataLayer, page: this.pageDataLayer };
  }

  setEventsDataLayer(event: string) {
    const date = new Date();
    const time = +date;
    const timeFormatted = date.toString();
    this.dataLayer = { ...this.dataLayer, event: event, time, timeFormatted };
    //@ts-ignore
    dataLayer.push(this.dataLayer);
    //@ts-ignore
    console.log(dataLayer);
  }
}
