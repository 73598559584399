import { BehaviorSubject, Observable } from 'rxjs';

export abstract class SessionStore<T> {

  private state$: BehaviorSubject<T>;
  private _storeName: string;

  constructor(storeName: string) {
      this._storeName = storeName;
      this.state$ = new BehaviorSubject<T>(undefined);
      sessionStorage.getItem(this._storeName) ? this.store(JSON.parse(sessionStorage.getItem(this._storeName))): null;
  }

  get = (): T => this.state$.getValue();

  get$ = (): Observable<T> => this.state$.asObservable();

  store = (nextState: T) => {
      this.state$.next(nextState);
      sessionStorage.setItem(this._storeName, JSON.stringify(nextState));  
  } 

  remove = () => {
    sessionStorage.removeItem(this._storeName);
  } 
}