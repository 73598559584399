import { MonoTypeOperatorFunction, Observable, filter, of, switchMap, take, tap } from "rxjs";
import { VeggaResponse } from "../http/veggaResponse";

export const ID = 'id';
/**
 * Checks if passed response has synchronous value, if so, returns this value 
 * as observable instead making a HTTP request with passed request.
 * Also filters responses that has the same value
 * @param request$ HttpRequest to perform
 * @param response veggaResponse associated to request
 * @returns Observable containing requested data
 */
export const handleResponse = <T>(request$: Observable<any>, response: VeggaResponse<T>): Observable<T> => 
    request$.pipe(
        take(1),
        switchMap((res) => res ? of(res) : request$),
        filter((res => JSON.stringify(response.value) !== JSON.stringify(res)))
    );

/**
 * Updates value on a collection-like response when
 * an edit has been performed to one of its elements. 
 * e.g. A response that contains a list of devices is updated when user edits a
 * device through an updateDevice endpoint.
 * @param response VeggaResponse that contains the list of items
 * @param id Identificator to find updated item in the list
 * @param idKey Key name belonging to item identification property
 * @returns Observable containing requested data
 */
export const updateCollectionFn = <T, U>(response: VeggaResponse<T>, id: number | string, idKey: string): MonoTypeOperatorFunction<U> => {
    const collection = response.value;
    if (Array.isArray(collection)) {
        return tap((updatedItem: U) => {
            const updatedItemIndex = collection.findIndex((collectionItem: any) => collectionItem[idKey] === id);
            collection[updatedItemIndex] = updatedItem;
            response.set([...collection] as T);
        })
    } else {
        // Objects TO DO
    }
}