import { DeviceItem, IrrigationAuditFilter, IrrigationAuditItem, IrrigationAuditResultsResponse, IrrigationAuditResultsSeries, IrrigationAuditUpdateRequest, IrrigationAuditsApi, NewIrrigationAuditItem } from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { VeggaFacade } from '../vegga-facade/vegga-facade';

@Injectable('auditsFacade')
export class AuditsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private auditsResponse = new VeggaResponse<IrrigationAuditItem[]>();
  private auditResultResponse = new VeggaResponse<IrrigationAuditResultsResponse>();
  private auditPreviewResultResponse = new VeggaResponse<IrrigationAuditResultsResponse>();
  private auditDevicesResponse = new VeggaResponse<DeviceItem[]>();
  private irrigationAuditsApi: IrrigationAuditsApi;

  constructor() {
    this.irrigationAuditsApi = new IrrigationAuditsApi();
    this.irrigationAuditsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get loader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get audits$(): Observable<IrrigationAuditItem[]> {
    return this.auditsResponse.value$;
  }

  get audits(): IrrigationAuditItem[] {
    return this.auditsResponse.value;
  }

  get auditsError(): Observable<any> {
    return this.auditsResponse.error$;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get auditResult$(): Observable<IrrigationAuditResultsResponse> {
    return this.auditResultResponse.value$;
  }

  get auditResult(): IrrigationAuditResultsResponse {
    return this.auditResultResponse.value
  }

  get auditResultError(): Observable<any> {
    return this.auditResultResponse.error$;
  }

  get auditPreviewResult$(): Observable<IrrigationAuditResultsResponse> {
    return this.auditPreviewResultResponse.value$;
  }

  get auditPreviewResultError(): Observable<any> {
    return this.auditPreviewResultResponse.error$;
  }

  get auditDevices$(): Observable<DeviceItem[]> {
    return this.auditDevicesResponse.value$;
  }

  get auditDevicesError(): Observable<any> {
    return this.auditDevicesResponse.error$;
  }

  loadAudits() {
    const req$ = this.irrigationAuditsApi.getIrrigationAuditList()

    const subscription = handleResponse(req$, this.auditsResponse).subscribe({
      next: (fert) => { this.auditsResponse.set(fert) },
      error: (err) => { this.auditsResponse.setError(err, {}) },
    });

    this.veggaFacade.loader.waitFor(subscription);
  }

  loadAuditResult(auditId: number): void {
    const req$ = this.irrigationAuditsApi.getIrrigationAuditResults({ id: auditId })

   const subscription = handleResponse(req$, this.auditResultResponse).subscribe({
      next: (fert) => { this.auditResultResponse.set(fert) },
      error: (err) => { this.auditResultResponse.setError(err, {}) },
    });

    this.veggaFacade.loader.waitFor(subscription);
  }

  loadAuditResultPreview(filters: IrrigationAuditFilter): void {
    this.auditPreviewResultResponse.clear();
    const req$ = this.irrigationAuditsApi.saveIrrigationAudit({ newIrrigationAuditRequest: { filters }, getResult: true });

    const subscription = handleResponse(req$, this.auditPreviewResultResponse).subscribe({
      next: (fert) => { this.auditPreviewResultResponse.set(fert) },
      error: (err) => { this.auditPreviewResultResponse.setError(err, {}) },
    });

    this.veggaFacade.loader.waitFor(subscription);
  }

  loadAuditDevices(): void {
    const req$ = this.irrigationAuditsApi.getDeviceList();

    const subscription = handleResponse(req$, this.auditDevicesResponse).subscribe({
      next: (devices) => { this.auditDevicesResponse.set(devices) },
      error: (err) => { this.auditDevicesResponse.setError(err, {}) },
    });

    this.loader.waitFor(subscription);
  }

  postIrrigationAudit(audit: NewIrrigationAuditItem): Observable<NewIrrigationAuditItem> {
    return this.irrigationAuditsApi.saveIrrigationAudit({ newIrrigationAuditRequest: audit })
      .pipe(tap(audit => (this.auditsResponse.set([...this.audits, audit]))));
  }

  updateIrrigationAudit(auditId: number, auditUpdateReq: IrrigationAuditUpdateRequest): Observable<IrrigationAuditItem> {
    return this.irrigationAuditsApi.updateIrrigationAudit({ id: auditId, irrigationAuditUpdateRequest: auditUpdateReq }).pipe(
      tap(updatedAudit => {
        const updatedAuditIndex = this.audits.findIndex((storedAudit: IrrigationAuditItem) => (storedAudit as any).id === auditId);
        this.audits[updatedAuditIndex] = updatedAudit;
        this.auditsResponse.set([...this.audits]);
        
        // update audit result response to show new results when changing to detail after saving edit
        this.auditResultResponse.set({...this.auditPreviewResultResponse.value});
      }),
    );
  }

  deleteAudit(auditId: number): Observable<void> {
    return this.irrigationAuditsApi.deleteIrrigationAudit({ id: auditId })
      .pipe(
        tap(() =>
        (this.auditsResponse.set(this.audits.filter((audit: IrrigationAuditItem) =>
          (audit as any).id !== auditId)))));
  }

  reset(): void {
    this.auditResultResponse.clear();
  }
}
