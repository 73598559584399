import { Auth } from '@vegga-types/auth';
import { User } from '@vegga-types/user';
import { Organization } from '@vegga-types/organization';
import { LocaleStore } from '../models/locale.store';
import { OrganizationStore } from '../organization/organization.store';

export class AuthStore extends LocaleStore<Auth> {

    private static instance: AuthStore;

    private constructor(storeName: string){
        super(storeName);
    }

    static getInstance(): AuthStore {
        if (!AuthStore.instance) {
            AuthStore.instance = new AuthStore('auth_store');
        }
        return AuthStore.instance;
    }

    storeAuth(auth: Auth) {
        this.store(auth);
        const organizationStore = OrganizationStore.getInstance()
        organizationStore.storeOrganization(this.getDefaultOrganization());
    }

    getNumberOfOrganizations(): number {
        return this.get().organizations.length;
    }

    getDefaultOrganization(): Organization {
        return this.get().organizations[0];
    }

    getOrganization(id: number): Organization {
        return this.get().organizations.filter((organization: Organization) => organization.id === id)[0];
    }

    getLoggedInUser(): User {
        return this.get().user;
    }
}