import {
  ExpansionConfigurationModuleRequest,
  ExpansionConfigurationModuleResponse,
  ExpansionModuleItemRequest,
  ExpansionModuleItemResponse,
  ModulesApi,
} from "@vegga-api-clients/irrigation-control-service";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Inject, Injectable } from "../../di";
import { VeggaLoader } from "../../http/veggaLoader";
import { VeggaResponse } from "../../http/veggaResponse";
import { ID, updateCollectionFn } from "../common.facade";
import { VeggaFacade } from "../vegga-facade/vegga-facade";

@Injectable("communicationsFacade")
export class CommunicationsFacade {
  @Inject("veggaFacade") private veggaFacade: VeggaFacade;
  private modulesExpansionConfigResponse =
    new VeggaResponse<ExpansionConfigurationModuleResponse>();
  private modulesExpansionResponse = new VeggaResponse<
    ExpansionModuleItemResponse[]
  >();
  private moduleExpansionByIdResponse =
    new VeggaResponse<ExpansionModuleItemResponse>();
  private modulesApi: ModulesApi;
  private loader = new VeggaLoader();

  constructor() {
    this.modulesApi = new ModulesApi();
    this.modulesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get modulesExpansionConfig$(): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesExpansionConfigResponse.value$;
  }

  get modulesExpansion$(): Observable<ExpansionModuleItemResponse[]> {
    return this.modulesExpansionResponse.value$;
  }

  get modulesExpansion(): ExpansionModuleItemResponse[] {
    return this.modulesExpansionResponse.value;
  }

  get moduleExpansionById$(): Observable<ExpansionModuleItemResponse> {
    return this.moduleExpansionByIdResponse.value$;
  }

  loadModulesExpansionConfig(unitId: number) {
    const req$ = this.modulesApi.getExpansionConfig({ unitId });
    const subscription = req$.subscribe({
      next: (res) => {
        this.modulesExpansionConfigResponse.set(res);
      },
      error: (err) => {
        this.modulesExpansionConfigResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadModulesExpansion(unitId: number) {
    const req$ = this.modulesApi.getExpansionModules({ unitId });
    const subscription = req$.subscribe({
      next: (res) => {
        this.modulesExpansionResponse.set(res);
      },
      error: (err) => {
        this.modulesExpansionResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadModulesExpansionByModuleId(unitId: number, moduleId: number) {
    const req$ = this.modulesApi.getExpansionModule({ unitId, moduleId });
    const subscription = req$.subscribe({
      next: (res) => {
        this.moduleExpansionByIdResponse.set(res);
      },
      error: (err) => {
        this.moduleExpansionByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  updateExpansionConfig(
    unitId: number,
    expansionConfigurationModuleRequest: ExpansionConfigurationModuleRequest
  ): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesApi.updateExpansionConfig({
      unitId,
      expansionConfigurationModuleRequest,
    });
  }

  updateExpansionModuleItem(
    unitId: number,
    moduleId: number,
    expansionModuleItemRequest: ExpansionModuleItemRequest
  ): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesApi
      .updateExpansionModuleItem({
        unitId,
        moduleId,
        expansionModuleItemRequest,
      })
      .pipe(updateCollectionFn(this.modulesExpansionResponse, moduleId, ID));
  }

  reset(): void {
    this.moduleExpansionByIdResponse.clear();
  }

  clear(): void {
    this.modulesExpansionResponse.clear();
    this.moduleExpansionByIdResponse.clear();
    this.modulesExpansionConfigResponse.clear();
  }
}
