import {
  ControlPointDetailItem,
  DeviceType,
  ExploitationControlPointHistoryItem,
  ExploitationDeviceDetailResponse,
  ExploitationParcelDataDetailResponse,
  ExploitationParcelDetailResponse,
  ExploitationsApi,
  MapsApi,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { MapsEntitiesEnum } from './maps.entities';
import { mockedDeviceEl1, mockedDeviceEl2, mockedMeteoStationEl, mockedPlotEl } from './mocks';

@Injectable('mapsFacade')
export class MapsFacade {
  private farmsResponse = new VeggaResponse<unknown>();
  private elementDataByIdResponse = new VeggaResponse<unknown>();
  private synopticsResponse = new VeggaResponse<ExploitationDeviceDetailResponse>();
  private activeSynopticsResponse = new VeggaResponse<ExploitationDeviceDetailResponse>();
  private sectorDataResponse = new VeggaResponse<ExploitationParcelDataDetailResponse>();
  private sectorDataHistoryResponse = new VeggaResponse<ExploitationParcelDetailResponse>();
  private controlPointResponse = new VeggaResponse<ControlPointDetailItem>();
  private controlPointHistoryResponse = new VeggaResponse<ExploitationControlPointHistoryItem[]>();
  private mapsApi: MapsApi;
  private exploitationsApi: ExploitationsApi;
  private loader = new VeggaLoader();
  private controlPointHistoryLoader = new VeggaLoader();

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }

  get isControlPointHistoryLoading$(): Observable<boolean> {
    return this.controlPointHistoryLoader.isLoading$;
  }

  get elementDataById$(): Observable<unknown> {
    return this.elementDataByIdResponse.value$;
  }

  get farmsResponse$(): Observable<unknown> {
    return this.farmsResponse.value$;
  }

  get synoptics$(): Observable<unknown> {
    return this.synopticsResponse.value$;
  }

  get activeSynoptics$(): Observable<unknown> {
    return this.activeSynopticsResponse.value$;
  }

  get synoptics(): ExploitationDeviceDetailResponse {
    return this.synopticsResponse.value;
  }

  get activeSynoptics(): ExploitationDeviceDetailResponse {
    return this.activeSynopticsResponse.value;
  }

  get sectorData$(): Observable<unknown> {
    return this.sectorDataResponse.value$;
  }

  get sectorDataHistory$(): Observable<unknown> {
    return this.sectorDataHistoryResponse.value$;
  }

  get controlPoint$(): Observable<ControlPointDetailItem> {
    return this.controlPointResponse.value$;
  }

  get controlPoint(): ControlPointDetailItem {
    return this.controlPointResponse.value;
  }

  get controlPointHistory$(): Observable<unknown> {
    return this.controlPointHistoryResponse.value$;
  }

  constructor() {
    this.mapsApi = new MapsApi();
    this.mapsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.exploitationsApi = new ExploitationsApi();
    this.exploitationsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  loadElementDataById(entityType: MapsEntitiesEnum, id: number) {
    // const req$ = this.mapsApi.getMaps();
    let res;
    switch (entityType) {
      case MapsEntitiesEnum.DEVICE:
        res = id === 1 ? mockedDeviceEl1 : mockedDeviceEl2;
        break;
      case MapsEntitiesEnum.METEO_STATION:
        res = mockedMeteoStationEl;
        break;
      case MapsEntitiesEnum.SECTOR:
        res = mockedPlotEl;
        break;
    }

    const req$ = of(res);

    req$.subscribe({
      next: maps => {
        this.elementDataByIdResponse.set(maps);
      },
      error: err => {
        this.elementDataByIdResponse.setError(err, {});
      },
    });
  }

  loadMapElementsData() {
    // const req$ = of(mockData);
    const req$ = this.exploitationsApi.getAllFarms();

    req$.subscribe({
      next: maps => {
        this.farmsResponse.set(maps);
      },
      error: err => {
        this.farmsResponse.setError(err, {});
      },
    });
  }

  loadSynoptics(deviceType: DeviceType, deviceId: number, onlyActive = false): void {
    const req$ = this.exploitationsApi.getDeviceSynoptic({ deviceType, id: deviceId, active: onlyActive });
    const subscription = req$.subscribe({
      next: syn => {
        if (onlyActive) {
          this.activeSynopticsResponse.set(syn);
        } else {
          this.synopticsResponse.set(syn);
        }
      },
      error: err => {
        this.synopticsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadSectorData(deviceType: DeviceType, deviceId: number, sectorId: number): void {
    const req$ = this.exploitationsApi.getSectorsData({ deviceId, deviceType, sector: sectorId });
    const subscription = req$.subscribe({
      next: sectorData => {
        this.sectorDataResponse.set(sectorData);
      },
      error: err => {
        this.sectorDataResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadSectorHistory(deviceType: DeviceType, deviceId: number, sectorId: number): void {
    const req$ = this.exploitationsApi.getSectorsDataHistory({ deviceId, deviceType, sector: sectorId });
    const subscription = req$.subscribe({
      next: sectorDataHistory => {
        this.sectorDataHistoryResponse.set(sectorDataHistory);
      },
      error: err => {
        this.sectorDataHistoryResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPoint(id: number): void {
    const req$ = this.exploitationsApi.getControlPoint({ id });
    const subscription = req$.subscribe({
      next: controlPoint => {
        this.controlPointResponse.set(controlPoint);
      },
      error: err => {
        this.controlPointResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointHistory(id: number): void {
    const req$ = this.exploitationsApi.getControlPointDataHistory({ id });
    const subscription = req$.subscribe({
      next: controlPointDataHistory => {
        this.controlPointHistoryResponse.set(controlPointDataHistory);
      },
      error: err => {
        this.controlPointHistoryResponse.setError(err, {});
      },
    });

    this.controlPointHistoryLoader.waitFor(subscription);
  }

  clearControlPointResponse(): void {
    this.controlPointResponse.clearValue();
    this.controlPointHistoryResponse.clearValue();
  }

  clear(): void {
    this.farmsResponse.clear();
    this.elementDataByIdResponse.clear();
    this.synopticsResponse.clear();
    this.activeSynopticsResponse.clear();
    this.sectorDataResponse.clear();
    this.sectorDataHistoryResponse.clear();
    this.controlPointResponse.clear();
    this.controlPointHistoryResponse.clear();
  }
}
