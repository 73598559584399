import { Version } from '@vegga-types/version';
import { SessionStore } from '../models/session.store';

export class VersioningStore extends SessionStore<Version[]> {

    private static instance: VersioningStore;

    private constructor(storeName: string){
        super(storeName);
    }

    static getInstance(): VersioningStore {
        if (!VersioningStore.instance) {
            VersioningStore.instance = new VersioningStore('version_store');
        }
        return VersioningStore.instance;
    }

    storeVersion(version: Version[]) {
        this.store(version);
    }
}