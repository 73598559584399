import { Injectable } from '../../di';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DeviceType, LinkBoxApi, LinkBoxItem } from '@vegga-api-clients/irrigation-control-service';
import { VeggaResponse } from '../../http/veggaResponse';
import { HttpErrorResponse } from '../../http';

@Injectable('linkBoxFacade')
export class LinkBoxFacade {
  private linkBoxApi: LinkBoxApi;
  private linkboxResponse = new VeggaResponse<LinkBoxItem[], HttpErrorResponse>();

  get linkBox$(): Observable<LinkBoxItem[]> {
    return this.linkboxResponse.value$;
  }

  get linkBoxError$(): Observable<HttpErrorResponse> {
    return this.linkboxResponse.error$;
  }

  get linkBox(): LinkBoxItem[] {
    return this.linkboxResponse.value;
  }

  constructor() {
    this.linkBoxApi = new LinkBoxApi();
    this.linkBoxApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  /**
   * Method to load the maps  for a given ID.
   *
   * @param deviceType - The device type (A4500,A2500,ABIT,A7000,A4000,A5500)
   * @param id - The ID of device.
   */

  loadLinkBox(deviceType: DeviceType, id: number) {
    const req$ = this.linkBoxApi.getActiveLinkBox({ deviceType, id });

    req$.subscribe({
      next: (linkBoxes: LinkBoxItem[]) => {
        this.linkboxResponse.set(linkBoxes);
      },
      error: (err: HttpErrorResponse) => {
        this.linkboxResponse.setError(err, {});
      },
    });
  }
}
