import { debounce, interval, map, Observable, Subject, switchMap, take, tap, timer } from "rxjs";
import { environment } from "../../../environments/environment";
import { Inject, Injectable } from "../../di";
import { VeggaResponse } from "../../http/veggaResponse";
import { handleResponse } from "../common.facade";
import { HttpClient } from "../../http";
import { DeviceType, IOSelectorApi } from "@vegga-api-clients/irrigation-control-service";
import {
  GetIOListRequest,
  ValidateIOSelectorListRequest,
} from "@vegga-api-clients/irrigation-control-service/lib/apis/IOSelectorApi";
import { IOSelectorItemResponse } from "@vegga-api-clients/irrigation-control-service/lib/models";
import { IOSelectorItemRequest } from "@vegga-api-clients/irrigation-control-service/lib/models/IOSelectorItemRequest";

@Injectable("ioFacade")
export class IoFacade {
  @Inject("httpClient") private httpClient: HttpClient;

  private configuredOutputsResponse = new VeggaResponse<IOSelectorItemResponse[]>();

  private stagingOutputsResponse = new VeggaResponse<IOSelectorItemResponse[]>();

  private stagingOutputRequest: ValidateIOSelectorListRequest = {
    deviceId: 0,
    deviceType: DeviceType.A4500,
    iOSelectorItemRequest: [],
  };

  private cancelInitialStagingIntervalSubject = new Subject<void>();

  /**
   * Configured unit/device Outputs response
   */
  get configuredOutputs(): Observable<IOSelectorItemResponse[]> {
    return this.configuredOutputsResponse.value$;
  }

  /**
   * Configured unit/device Outputs error
   */
  get configuredOutputsError(): Observable<any> {
    return this.configuredOutputsResponse.error$;
  }

  /**
   * Staging unit/device Outputs response
   */
  get stagingOutputs(): Observable<IOSelectorItemResponse[]> {
    return this.stagingOutputsResponse.value$;
  }

  /**
   * Staging unit/device Outputs error
   */
  get stagingOutputsError(): Observable<any> {
    return this.stagingOutputsResponse.error$;
  }

  private ioSelectorApi: IOSelectorApi;

  constructor() {
    this.ioSelectorApi = new IOSelectorApi();
    this.ioSelectorApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  /**
   * Loads the configured outputs of a device
   * @param config
   */
  loadConfiguredOutputs(config: GetIOListRequest) {
    this.configuredOutputsResponse.clear();
    const req$ = this.ioSelectorApi.getIOList(config);
    handleResponse(req$, this.configuredOutputsResponse).subscribe({
      next: (outputs) => {
        this.configuredOutputsResponse.set(outputs);
      },
      error: (err) => {
        this.configuredOutputsResponse.setError(err, {});
      },
    });
  }

  /**
   * Initialize staging outputs of a device
   * @param id
   * @param deviceType
   */
  initializationStagingOutputs(id: number, deviceType: DeviceType) {
    this.stagingOutputsResponse.clear();
    this.stagingOutputRequest.iOSelectorItemRequest = [];
    this.stagingOutputRequest.deviceId = id;
    this.stagingOutputRequest.deviceType = DeviceType[deviceType];

    this.cancelInitialStagingIntervalSubject
      .pipe(
        debounce(() => timer(200)),
        take(1),
        switchMap(() => {
          const req$ = this.ioSelectorApi.validateIOSelectorList(this.stagingOutputRequest);
          return handleResponse(req$, this.stagingOutputsResponse);
        })
      )
      .subscribe({
        next: (outputs) => {
          this.stagingOutputsResponse.set(outputs);
        },
        error: (err) => {
          this.stagingOutputsResponse.setError(err, {});
        },
      });
  }

  /**
   * Update staging outputs of a device
   * @param currentOutput
   */
  initializeStagingOutputs(currentOutput: IOSelectorItemRequest) {
    this.configuredOutputsResponse.value$.subscribe({
      next: (configurationOutputs) => {
        let output = configurationOutputs.find((ioSelectorItemResponse) => {
          return (
            ioSelectorItemResponse.output === currentOutput.output &&
            ioSelectorItemResponse.header === currentOutput.header &&
            (ioSelectorItemResponse as unknown as IOSelectorItemRequest).elementType === currentOutput.elementType &&
            (ioSelectorItemResponse as unknown as IOSelectorItemRequest).outputType === currentOutput.outputType &&
            ioSelectorItemResponse.element === currentOutput.element
          );
        }) as unknown as IOSelectorItemRequest;
        output = output
          ? ({
              ...output,
              id: output && output.id ? output.id : currentOutput.elementId,
              elementId: currentOutput.elementId,
            } as unknown as IOSelectorItemRequest)
          : { ...currentOutput, id: currentOutput.elementId };
        this.stagingOutputRequest.iOSelectorItemRequest = this.stagingOutputRequest.iOSelectorItemRequest.filter(
          (item) => item.elementId !== currentOutput.elementId
        );
        this.stagingOutputRequest.iOSelectorItemRequest.push(output);
        this.cancelInitialStagingIntervalSubject.next();
      },
    });
  }

  /**
   * Update staging outputs of a device
   * @param currentOutput
   * @param initialize
   */
  updateStagingOutputs(currentOutput: IOSelectorItemRequest) {
    this.stagingOutputRequest.iOSelectorItemRequest = this.stagingOutputRequest.iOSelectorItemRequest.map((item) => {
      if (
        item.outputType === currentOutput.outputType &&
        item.elementType === currentOutput.elementType &&
        item.header === currentOutput.header &&
        item.elementId === currentOutput.elementId &&
        item.element === currentOutput.element
      ) {
        item.output = currentOutput.output;
      }
      return item;
    });

    const req$ = this.ioSelectorApi.validateIOSelectorList(this.stagingOutputRequest);
    handleResponse(req$, this.stagingOutputsResponse).subscribe({
      next: (outputs) => {
        this.stagingOutputsResponse.set(outputs);
      },
      error: (err) => {
        this.stagingOutputsResponse.setError(err, {});
      },
    });
  }

  /**
   * Validate if there is any duplicated output. Returns true if all outputs are unique in the list
   */
  validateOutputsToTheCurrentView() {
    return this.ioSelectorApi.validateIOSelectorList(this.stagingOutputRequest).pipe(
      map((outputs) => {
        return outputs.every((output) => !output.multipleRegistryOutput);
      })
    );
  }
}
